import Button from 'components/Button';
import CheckForm from 'components/CheckForm';
import ContentTable from 'components/ContentTable';
import Currency from 'components/Currency';
import InputForm from 'components/InputForm';
import Modal from 'components/Modal';
import TextareaForm from 'components/TextareaForm';
import { useToast } from 'components/Toast';
import { useConfirmMerchantShipping, usePrepareMerchantShipping, useMergeDeliveries } from 'hooks/use-orders';
import useToggle from 'hooks/use-toggle';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import translasteStatus from 'lib/translasteStatus';
import { PDFDownloadLink } from '@react-pdf/renderer';
import OrderPdf from 'components/OrderPdf';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/use-auth';
import isSuccessResponse from 'lib/isSuccessResponse';
import { deliveryTypes } from 'lib/constants/productConstants';

const MerchantShippingDetails = ({
	orderId,
	orderData,
	delivery,
	merchantShipping,
	setTotalAmount,
	merchantShippingIndex,
	getLazyOrder,
	shippingNotes,
}) => {
	const [openModal, setOpenModal] = useToggle();
	const [subtotalAmount, setSubtotalAmount] = useState(0);
	const [isFormFilled, setIsFormFilled] = useState(false);
	const [orderItemFields] = useState([
		{
			id: 0,
			grossDiscountedPrice: '',
			grossPrice: '',
			productTitle: '',
			boughtQuantity: '',
			sent: false,
			substituted: false,
			sentQuantity: null,
			Variant: {
				title: '',
				price: '',
				discountedPrice: '',
			},
		},
	]);
	const [disabled, setDisabled] = useState(
		!(merchantShipping?.status === 'new' || merchantShipping?.status === 'processing')
	);
	const [confirmMerchantShipping] = useConfirmMerchantShipping();
	const [prepareMerchantShipping] = usePrepareMerchantShipping();
	const [mergeDeliveries] = useMergeDeliveries();

	const {
		register,
		trigger,
		handleSubmit,
		control,
		watch,
		setValue,
		getValues,
		formState: { isValid, errors },
		resetField,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			merchantShippingForm: {
				orderItems: orderItemFields,
				note: '',
				packages: '',
				weight: '',
				noteMerged: '',
				packagesMerged: '',
				weightMerged: '',
				status: '',
			},
		},
	});

	useEffect(() => {
		if (merchantShipping) {
			setValue('merchantShippingForm', {
				orderItems: merchantShipping.OrderItems.map(orderItem => ({
					...orderItem,
					sentQuantity: orderItem.sentQuantity ?? orderItem.boughtQuantity,
					oldSentQuantity: orderItem.sentQuantity ?? orderItem.boughtQuantity,
					grossDiscountedPrice:
						typeof orderItem.grossDiscountedPrice === 'number' ? orderItem.grossDiscountedPrice : '',
				})),
				note: merchantShipping.note || merchantShipping.Merchant?.logisticsObservations || null,
				packages: merchantShipping.packages || null,
				weight: merchantShipping.weight || null,
				noteMerged: null,
				packagesMerged: null,
				weightMerged: null,
				status: merchantShipping.status,
			});

			setSubtotalAmount(
				merchantShipping.OrderItems.reduce(
					(sum, { grossDiscountedPrice, grossPrice, sentQuantity, boughtQuantity }) =>
						sum +
						(grossDiscountedPrice || grossPrice) * (sentQuantity === null ? boughtQuantity : sentQuantity),
					0
				)
			);
			setIsFormFilled(true);
		}
	}, [merchantShipping, setValue, setSubtotalAmount]);

	return (
		isFormFilled && (
			<div>
				<div className={`${disabled && 'bg-gray-200'} mb-5 border border-dashed border-coral-300`}>
					<h2 className='mt-2 text-center text-lg font-medium'>
						{merchantShipping?.Merchant.commercialName}
					</h2>
					<p className='mt-2 text-center text-lg'>
						<span className='border border-coral-500 py-1 px-2 text-center font-medium text-coral-500'>
							{translasteStatus(getValues('merchantShippingForm.status'))}
						</span>
					</p>
					<ContentTable
						columns={[
							'Artículo',
							'Coste',
							'Cantidad',
							'Cantidad enviada',
							'Estado envio',
							'Producto Sustituido',
							'Total Compra',
						]}
						content={merchantShipping.OrderItems}
						render={(orderItem, index) => (
							<tr key={index} className='border-t border-dashed border-coral-300 text-sm text-gray-700'>
								<td className='px-4 py-2'>
									{orderItem.Variant && (
										<Link to={`/dashboard/catalogo/productos/edit/${orderItem.Variant.Product.id}`}>
											{orderItem.Variant.Product.title}
										</Link>
									)}
									<div className='text-xs'>{orderItem.Variant?.title}</div>
									<div className='text-xs'>
										{orderItem.Variant?.barcode ? `EAN: ${orderItem.Variant?.barcode}` : ''}
										{orderItem.Variant?.barcode && orderItem.Variant?.sku && ' - '}
										{orderItem.Variant?.sku ? `SKU: ${orderItem.Variant?.sku}` : ''}
									</div>
								</td>
								<td className='px-4 py-2'>
									<Currency>{orderItem.grossDiscountedPrice || orderItem.grossPrice}</Currency>
								</td>
								<td className='px-4 py-2'>{orderItem.boughtQuantity}</td>
								<TdOrderItem
									setSubtotalAmount={setSubtotalAmount}
									setTotalAmount={setTotalAmount}
									orderItem={orderItem}
									orderItemIndex={index}
									control={control}
									register={register}
									setValue={setValue}
									getValues={getValues}
									watch={watch}
									authorizeProductSubstitution={orderData?.authorizeProductSubstitution}
									disabled={disabled}
								/>
							</tr>
						)}
						empty={null}
					/>
					<div className='ml-auto mr-12 w-48'>
						<p className='flex'>
							<span className='flex-1 text-right'>Subtotal:</span>
							<Currency className='flex-1 text-right'>{subtotalAmount}</Currency>
						</p>
					</div>
					<div className='mt-2 flex w-full flex-col border-t border-dashed border-coral-300 pt-2'>
						<MerchantShippingElement
							setDisabled={setDisabled}
							control={control}
							register={register}
							handleSubmit={handleSubmit}
							merchantShipping={merchantShipping}
							orderId={orderId}
							openModal={openModal}
							setOpenModal={setOpenModal}
							watch={watch}
							setValue={setValue}
							trigger={trigger}
							isValid={isValid}
							errors={errors}
							resetField={resetField}
							disabled={disabled}
							confirmMerchantShipping={confirmMerchantShipping}
							prepareMerchantShipping={prepareMerchantShipping}
							mergeDeliveries={mergeDeliveries}
							orderData={orderData}
							delivery={delivery}
							merchantShippingForm={getValues('merchantShippingForm')}
							merchantShippingIndex={merchantShippingIndex}
							getLazyOrder={getLazyOrder}
							shippingNotes={shippingNotes}
						/>
					</div>
				</div>
			</div>
		)
	);
};

const TdOrderItem = ({
	setTotalAmount,
	setSubtotalAmount,
	orderItem,
	orderItemIndex,
	control,
	register,
	setValue,
	getValues,
	watch,
	authorizeProductSubstitution,
	disabled,
}) => {
	const grossPrice = orderItem.grossDiscountedPrice || orderItem.grossPrice;
	const [orderItemAmount, setOrderItemAmount] = useState(
		grossPrice * (orderItem.sentQuantity ?? orderItem.boughtQuantity)
	);
	const [disableSubsituted, setDisableSubsituted] = useState(false);

	const returnQuantityStatus = _orderItem => {
		const _sentQuantity = getValues(`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`);
		// Changes the status
		if (Number.parseInt(_sentQuantity) === _orderItem.boughtQuantity) {
			return 'Enviado completo';
		} else if (
			Number.parseInt(_sentQuantity) === 0 ||
			_sentQuantity === '' ||
			_sentQuantity === undefined ||
			_sentQuantity === null
		) {
			return 'No disponible';
		} else if (_sentQuantity < _orderItem.boughtQuantity) {
			return 'Enviado parcial';
		}
	};

	useEffect(() => {
		// While sentQuantity changes
		const subscription = watch(({ merchantShippingForm }, { name }) => {
			if (name === `merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`) {
				const oldSentQuantity = Number.parseInt(
					merchantShippingForm.orderItems[orderItemIndex].oldSentQuantity
				);
				const newSentQuantity =
					Number.parseInt(merchantShippingForm.orderItems[orderItemIndex].sentQuantity) || 0;

				// Sets sentQuantity field to the requested quantity
				// whenever it is more than the requested quantity
				if (newSentQuantity > orderItem.boughtQuantity) {
					setValue(
						`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`,
						orderItem.boughtQuantity
					);
				}
				// Changes Subsituted value to false whenever `newSentQuantity` is zero
				if (newSentQuantity === 0) {
					setValue(`merchantShippingForm.orderItems.${orderItemIndex}.substituted`, false);
				}
				if (merchantShippingForm.orderItems[orderItemIndex].sentQuantity === '') {
					setValue(`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`, 0);
				}
				setDisableSubsituted(newSentQuantity === 0);

				// Changes the orderItemAmount
				setOrderItemAmount(grossPrice * newSentQuantity);

				// Changes the total and subTotal Amount
				setSubtotalAmount(prev => prev + (newSentQuantity - oldSentQuantity) * grossPrice);
				setTotalAmount(prev => prev + (newSentQuantity - oldSentQuantity) * grossPrice);

				setValue(`merchantShippingForm.orderItems.${orderItemIndex}.oldSentQuantity`, newSentQuantity);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, orderItem.boughtQuantity, orderItemIndex, grossPrice, setSubtotalAmount, setTotalAmount, setValue]);

	return (
		<>
			<td className='bg-coral-100 px-4 py-2'>
				<InputForm
					name={`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`}
					control={control}
					register={register}
					type='number'
					max={orderItem.boughtQuantity}
					min='0'
					width='1'
					disabled={
						getValues('merchantShippingForm.status') !== 'new' &&
						getValues('merchantShippingForm.status') !== 'processing'
					}
				/>
			</td>
			<td className='bg-coral-100 px-4 py-2'>{returnQuantityStatus(orderItem)}</td>
			<td className='bg-coral-100 px-4 py-2 text-center'>
				<CheckForm
					name={`merchantShippingForm.orderItems.${orderItemIndex}.substituted`}
					control={control}
					className='flex justify-center'
					disabled={disabled || disableSubsituted || !authorizeProductSubstitution}
				/>
			</td>
			<td className='px-4 py-2 text-center'>
				<Currency>{orderItemAmount}</Currency>
			</td>
		</>
	);
};

const modalTypes = Object.freeze({
	STANDARD: 'standard',
	MUST_BE_ACCEPTED: 'mustBeAccepted',
	MUST_CONFIRM_MERGE: 'mustConfimMerge',
	SINGLE_DELIVERY_CONFIRMED: 'singleDeliveryConfirmed',
	MULTIPLE_DELIVERY_INPUT: 'multipleDeliveryInput',
	MULTIPLE_DELIVERY_CONFIRMED: 'mutipleDeliveryConfirmed',
	CANCELED: 'merchantShippingCanceled',
	NONE: 'none',
});

const MerchantShippingElement = ({
	control,
	register,
	handleSubmit,
	merchantShipping,
	watch,
	orderId,
	openModal,
	setOpenModal,
	setValue,
	trigger,
	isValid,
	errors,
	resetField,
	confirmMerchantShipping,
	prepareMerchantShipping,
	mergeDeliveries,
	orderData,
	delivery,
	merchantShippingForm,
	merchantShippingIndex,
	getLazyOrder,
	shippingNotes,
}) => {
	const { logOut } = useAuth();
	const [emptyShipping, setEmptyShipping] = useState(false);
	const [readyToPrepare, setReadyToPrepare] = useState(
		merchantShipping.status === 'accepted' ||
			((merchantShipping.status === 'new' || merchantShipping.status === 'processing') &&
				delivery.deliveryType === deliveryTypes.STANDARD)
	);
	const [modalType, setModalType] = useState('');
	const [updating, setUpdating] = useState(false);
	const { addErrorMessage, addSuccessMessage } = useToast();

	useEffect(() => {
		const subscription = watch(({ merchantShippingForm }) => {
			if (
				merchantShippingForm.orderItems?.every(
					item => Number.parseInt(item.sentQuantity) === 0 || item.sentQuantity === ''
				)
			) {
				setEmptyShipping(true);
			} else {
				setEmptyShipping(false);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	useEffect(() => {
		if (emptyShipping) {
			setValue('merchantShippingForm.packages', 0);
			setValue('merchantShippingForm.weight', 0);
			setModalType(modalTypes.CANCELED);
		} else {
			resetModalType();
		}
	}, [emptyShipping, setValue]);

	useEffect(() => {
		resetModalType();
	}, []);

	const resetModalType = () => {
		if (emptyShipping) {
			setModalType(modalTypes.CANCELED);
		} else if (!readyToPrepare) {
			setModalType(modalTypes.MUST_BE_ACCEPTED);
		} else if (
			merchantShipping?.status === 'accepted' &&
			orderData?.Deliveries?.filter(
				delivery => delivery.deliveryType !== deliveryTypes.STANDARD && delivery.status === 'new'
			).length > 1
		) {
			setModalType(modalTypes.MUST_CONFIRM_MERGE);
		} else {
			setModalType(modalTypes.STANDARD);
		}
	};

	const handleModal = async () => {
		if (!isValid && !emptyShipping) {
			// Trigger forces a validation and fills errors
			await trigger(undefined, { shouldFocus: true });
			// We display the required fields
			const requiredFields = errors?.merchantShippingForm
				? Object.keys(errors.merchantShippingForm).join(', ')
				: '';
			addErrorMessage('Pedido', `Por favor rellene los campos requeridos: ${requiredFields}`);
		} else {
			setOpenModal(value => !value);
		}
	};

	const handleConfirmMerchantShipping = async (orderItems, merchantShippingInput) => {
		confirmMerchantShipping({
			variables: {
				OrderId: orderId,
				MerchantId: merchantShipping?.Merchant.id,
				DeliveryNumber: delivery.number,
				orderItems: orderItems,
				emptyShipping,
			},
		}).then(({ data }) => {
			setOpenModal(false);
			setUpdating(false);
			if (
				isSuccessResponse(
					data.ConfirmMerchantShipping,
					['Success'],
					logOut,
					addErrorMessage,
					'Confirmar pedido'
				)
			) {
				if (delivery.deliveryType === deliveryTypes.STANDARD && !emptyShipping) {
					handlePrepareMerchantShipping(merchantShippingInput);
				} else {
					setReadyToPrepare(true);
					setValue('merchantShippingForm.status', 'accepted');
					addSuccessMessage('Pedido', emptyShipping ? 'Rechazado correctamente' : 'Aceptado correctamente');
					getLazyOrder();
				}
			}
		});
	};

	const handlePrepareMerchantShipping = async merchantShippingInput => {
		prepareMerchantShipping({
			variables: {
				MerchantShippingNumber: merchantShipping.number,
				merchantShippingInput: merchantShippingInput,
			},
		}).then(({ data }) => {
			setOpenModal(false);
			setUpdating(false);
			if (
				isSuccessResponse(data.PrepareMerchantShipping, ['Success'], logOut, addErrorMessage, 'Preparar pedido')
			) {
				setReadyToPrepare(false);
				setValue('merchantShippingForm.status', 'prepared');
				addSuccessMessage('Pedido', 'Preparado correctamente');
				getLazyOrder();
			}
		});
	};

	const handleMergeDeliveries = async (orderItems, merchantShippingInput) => {
		mergeDeliveries({
			variables: {
				orderItems: orderItems,
			},
		}).then(({ data }) => {
			if (isSuccessResponse(data.MergeDeliveries, ['Success'], logOut, addErrorMessage, 'Unir envíos')) {
				prepareMerchantShipping({
					variables: {
						MerchantShippingNumber: data.MergeDeliveries.merchantShippingNumber,
						merchantShippingInput: merchantShippingInput,
					},
				}).then(({ data }) => {
					if (
						isSuccessResponse(
							data.PrepareMerchantShipping,
							['Success'],
							logOut,
							addErrorMessage,
							'Preparar pedido'
						)
					) {
						setReadyToPrepare(false);
						setValue('merchantShippingForm.status', 'prepared');
						addSuccessMessage('Pedido', 'Preparado correctamente');
						getLazyOrder();
					}
				});
			}
		});
	};

	const getButtonText = () => {
		if (merchantShipping.status === 'canceled') {
			return 'Pedido rechazado';
		}
		if (emptyShipping) {
			return 'Confirma anulación pedido';
		}
		return readyToPrepare ? 'Envío listo para recoger' : 'Aceptar envío';
	};

	// What will be displayed in each modal type
	const getModalObject = () => {
		let title = 'Confirmación';
		let description = '';
		let btnText = 'Confirmar';
		let cancelBtnText = 'Cancelar';

		// Standard deliveries or single deferred deliveries that are already confirmed
		if (modalType === modalTypes.STANDARD) {
			description =
				'Al "confirmar" se notificará a Logística que recoja este envío lo antes posible, y no se podrá anular.';
		}

		// Deferred merchantShippings that have not been confirmed yet
		if (modalType === modalTypes.MUST_BE_ACCEPTED) {
			description =
				'Al confirmar se aceptará la orden y no se podrá anular. Cuando los artículos estén listos deberás marcar el envío como preparado para ser recogido por Logística.';
		}

		// Deferred merchantShippings that have more than one can merge them on one Delivery
		if (modalType === modalTypes.MUST_CONFIRM_MERGE) {
			description =
				'Recuerda que el costo logistico de cada envio diferido es soportado por ti. Este pedido consta de varios envios. ¿Quieres juntarlos todos en una única expedicion?';
			btnText = 'Sí';
			cancelBtnText = 'No';
		}

		// User has chosen to deliver only this MerchantShipping
		if (modalType === modalTypes.SINGLE_DELIVERY_CONFIRMED) {
			description =
				'Al "confirmar" se notificará a Logística que recoja tan solo este envío lo antes posible, y no se podrá anular.';
		}

		// User has chosen to merge all MerchantShipping in one Delivery, so weight and packages must be entered
		if (modalType === modalTypes.MULTIPLE_DELIVERY_INPUT) {
			title = 'Envío conjunto';
		}

		// User has entered weight and packages
		if (modalType === modalTypes.MULTIPLE_DELIVERY_CONFIRMED) {
			description =
				'Al "confirmar" se notificará a Logística que recoja este envío conjunto lo antes posible, y no se podrá anular.';
		}

		// Merchant canceled the merchantShipping
		if (modalType === modalTypes.CANCELED) {
			description = 'Al confirmar el pedido quedará anulado y no se podrá revertir.';
		}

		return {
			title,
			description: <span className='text-lg leading-5 text-gray-500'>{description}</span>,
			btnText,
			cancelBtnText,
		};
	};

	// What will happen when confirming each modal type
	const getModalSubmit = () => {
		setUpdating(true);
		// Standard deliveries or deferred deliveries that have not been confirmed yet
		if (
			modalType === modalTypes.STANDARD ||
			modalType === modalTypes.MUST_BE_ACCEPTED ||
			modalType === modalTypes.CANCELED
		) {
			merchantShippingForm.orderItems = merchantShippingForm?.orderItems.map(orderItem => ({
				id: orderItem.id,
				substituted: orderItem.substituted,
				sentQuantity: orderItem.sentQuantity,
			}));
			const merchantShippingInput = {
				note: merchantShippingForm.note,
				weight: merchantShippingForm.weight,
				packages: merchantShippingForm.packages,
			};
			if (merchantShipping.status === 'accepted') {
				handlePrepareMerchantShipping(merchantShippingInput);
			} else {
				handleConfirmMerchantShipping(merchantShippingForm.orderItems, merchantShippingInput);
			}
			setModalType(modalTypes.NONE);
			setOpenModal(false);
		}

		// Deferred merchantShippings that have more than one can merge them on one Delivery
		if (modalType === modalTypes.MUST_CONFIRM_MERGE) {
			setModalType(modalTypes.MULTIPLE_DELIVERY_INPUT);
		}

		// User has chosen to deliver only this MerchantShipping
		if (modalType === modalTypes.SINGLE_DELIVERY_CONFIRMED) {
			const merchantShippingInput = {
				note: merchantShippingForm.note,
				weight: merchantShippingForm.weight,
				packages: merchantShippingForm.packages,
			};
			handlePrepareMerchantShipping(merchantShippingInput);
			setModalType(modalTypes.NONE);
			setOpenModal(false);
		}

		// User has chosen to merge all MerchantShipping in one Delivery, so weight and packages must be entered
		if (modalType === modalTypes.MULTIPLE_DELIVERY_INPUT) {
			setModalType(modalTypes.MULTIPLE_DELIVERY_CONFIRMED);
		}

		// User has entered weight and packages
		if (modalType === modalTypes.MULTIPLE_DELIVERY_CONFIRMED) {
			const deliveries = orderData.Deliveries.filter(
				delivery => delivery.deliveryType !== deliveryTypes.STANDARD && delivery.status === 'new'
			);
			const merchantShippings = deliveries.flatMap(delivery => delivery.MerchantShippings);
			let orderItems = merchantShippings.flatMap(merchantShipping => merchantShipping.OrderItems);
			orderItems = orderItems.map(orderItem => ({
				id: orderItem.id,
				substituted: orderItem.substituted,
				sentQuantity: orderItem.sentQuantity,
			}));
			const merchantShippingInputMerged = {
				note: merchantShippingForm.noteMerged,
				weight: merchantShippingForm.weightMerged,
				packages: merchantShippingForm.packagesMerged,
			};

			handleMergeDeliveries(orderItems, merchantShippingInputMerged);
			setModalType(modalTypes.NONE);
			setOpenModal(false);
		}
		setUpdating(false);
	};

	const getModalCancel = () => {
		if (modalType === modalTypes.MUST_CONFIRM_MERGE) {
			setModalType(modalTypes.SINGLE_DELIVERY_CONFIRMED);
		} else {
			if (
				modalType === modalTypes.MULTIPLE_DELIVERY_INPUT ||
				modalType === modalTypes.MULTIPLE_DELIVERY_CONFIRMED
			) {
				resetField('merchantShippingForm.packages');
				resetField('merchantShippingForm.weight');
				resetField('merchantShippingForm.packagesMerged');
				resetField('merchantShippingForm.weightMerged');
			}
			resetModalType();
			setOpenModal(false);
		}
	};

	return (
		<div className='p-6'>
			<div className='flex w-full flex-col lg:flex-row'>
				<div className='w-full'>
					<p className='w-full italic text-gray-400'>*Observaciones para logística</p>
					<TextareaForm
						name='merchantShippingForm.note'
						control={control}
						cols='80'
						className='mt-1 rounded-md border border-coral-300 p-4 text-lg outline-none focus:outline-none'
						placeholder='Escribir aquí posibles incidencias'
						disabled={!readyToPrepare}
						simple
					/>
				</div>

				<div className='flex w-1/3 flex-col justify-center'>
					<p className='w-full text-left italic text-gray-400'>
						*Número total de bultos que componen la expedición.
					</p>
					<InputForm
						name='merchantShippingForm.packages'
						control={control}
						register={register}
						type='number'
						label='Bultos'
						placeholder='Número de bultos'
						width='w-full'
						labelclass='w-1/4'
						min={emptyShipping ? '0' : 1}
						required={!emptyShipping && readyToPrepare}
						disabled={emptyShipping || !readyToPrepare}
						forcePositiveInteger
					/>
					<p className='w-full text-left italic text-gray-400'>
						*Peso total de la expedición en Kilos sin decimales.
					</p>
					<InputForm
						name='merchantShippingForm.weight'
						control={control}
						register={register}
						type='number'
						label='Peso'
						width='w-full'
						labelclass='w-1/4'
						placeholder='Peso'
						min={emptyShipping ? '0' : 1}
						required={!emptyShipping && readyToPrepare}
						disabled={emptyShipping || !readyToPrepare}
						forcePositiveInteger
					/>
				</div>
			</div>

			<div className='flex justify-end self-end'>
				{['new', 'processing', 'accepted', 'canceled'].includes(merchantShipping.status) ? (
					<Button
						className='w-40'
						disabled={updating || merchantShipping.status === 'canceled'}
						onClick={handleModal}
					>
						{getButtonText()}
					</Button>
				) : (
					<PDFDownloadLink
						document={
							<OrderPdf
								merchantShippingIndex={merchantShippingIndex}
								orderData={orderData}
								delivery={delivery}
								merchantShippingData={merchantShippingForm}
							/>
						}
						fileName={`Pedido ${merchantShipping?.number}`}
					>
						{({ loading }) =>
							loading ? (
								'Cargando documento...'
							) : (
								<div className='mt-4 block w-40 rounded-md bg-coral-500 py-2 px-3 text-center text-sm font-bold text-white shadow-md transition-all duration-200 hover:bg-coral-700 focus:shadow focus:shadow-outline focus:outline-none'>
									Descargar PDF: {merchantShipping?.number}
								</div>
							)
						}
					</PDFDownloadLink>
				)}
			</div>
			<div className='flex justify-end self-end'></div>

			<Modal
				modalObject={getModalObject()}
				openModal={openModal}
				setOpenModal={setOpenModal}
				onCancel={getModalCancel}
				handleSubmit={handleSubmit(() => getModalSubmit())}
			>
				{(modalType === modalTypes.STANDARD ||
					modalType === modalTypes.SINGLE_DELIVERY_CONFIRMED ||
					modalType === modalTypes.MULTIPLE_DELIVERY_CONFIRMED) &&
				shippingNotes ? (
					<div className='flex flex-col w-full gap-2'>
						<h2 className='text-xl bold'>Atención, ten en cuenta la nota del cliente</h2>
						<p className='border mt-1 p-4 text-lg rounded-md border-coral-300 outline-none focus:outline-none h-32 w-full'>
							{shippingNotes}
						</p>
					</div>
				) : null}

				{/* Input to fill in the merged delivery data */}
				{modalType === modalTypes.MULTIPLE_DELIVERY_INPUT && (
					<div className='flex w-full flex-col'>
						<div className='w-full'>
							<p className='w-full italic text-gray-400'>*Observaciones para logística</p>
							<TextareaForm
								name='merchantShippingForm.noteMerged'
								control={control}
								cols='80'
								className='mt-1 rounded-md border border-coral-300 p-4 text-lg outline-none focus:outline-none'
								placeholder='Escribir aquí posibles incidencias'
								disabled={!readyToPrepare}
								simple
							/>
						</div>

						<div className='flex w-1/2 flex-col justify-center'>
							<p className='w-full text-left italic text-gray-400'>
								*Número total de bultos que componen la expedición.
							</p>
							<InputForm
								name='merchantShippingForm.packagesMerged'
								control={control}
								register={register}
								type='number'
								label='Bultos'
								placeholder='Número de bultos'
								width='w-full'
								labelclass='w-1/3'
								min={1}
								required={readyToPrepare}
								forcePositiveInteger
							/>
							<p className='w-full text-left italic text-gray-400'>
								*Peso total de la expedición en Kilos sin decimales.
							</p>
							<InputForm
								name='merchantShippingForm.weightMerged'
								control={control}
								register={register}
								type='number'
								label='Peso'
								width='w-full'
								labelclass='w-1/3'
								placeholder='Peso'
								min={1}
								required={readyToPrepare}
								forcePositiveInteger
							/>
						</div>
					</div>
				)}
			</Modal>
		</div>
	);
};

export default MerchantShippingDetails;
