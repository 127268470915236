import React, { useState, useEffect, useContext, createContext } from 'react';
import { clearCache } from 'components/Apollo';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Loading from 'components/Loading';
import { USERINFO_FRAGMENT } from 'hooks/use-merchants';
import useConf from 'hooks/use-conf';

export const ME_QUERY = gql`
	query me($locale: String) {
		me(locale: $locale) {
			...UserInfo
		}
	}
	${USERINFO_FRAGMENT}
`;

const NOTIFICATION_COUNT_QUERY = gql`
	query NotificationCount {
		NotificationCount {
			merchantsCount
			ordersCount
			productsCount
		}
	}
`;
export const SIGNUP_MUTATION = gql`
	mutation SignUp($input: UserSignInput!) {
		SignUp(input: $input) {
			...UserInfo
		}
	}
	${USERINFO_FRAGMENT}
`;

export const useCreateUser = props =>
	useMutation(SIGNUP_MUTATION, {
		...props,
	});

export const SIGNIN_MUTATION = gql`
	mutation SignIn($input: UserSignInput!) {
		SignIn(input: $input) {
			... on User {
				...UserInfo
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${USERINFO_FRAGMENT}
`;

const RESTORE_PASSWORD_MUTATION = gql`
	mutation RestorePassword($email: String!) {
		RestorePassword(email: $email) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
			}
		}
	}
`;

const SIGNOUT_MUTATION = gql`
	mutation signout {
		SignOut
	}
`;

const CHANGEPASSWORD_MUTATION = gql`
	mutation ChangePassword($id: ID!, $password: String!) {
		ChangePassword(id: $id, password: $password) {
			... on User {
				...UserInfo
			}
			... on DatabaseFault {
				message
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${USERINFO_FRAGMENT}
`;

const PROFILEUPDATE_MUTATION = gql`
	mutation ProfileUpdate($id: ID, $input: ProfileUpdateInput!) {
		ProfileUpdate(id: $id, input: $input) {
			... on User {
				...UserInfo
			}
			... on DatabaseFault {
				message
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${USERINFO_FRAGMENT}
`;

const IMPERSONATEUSER_MUTATION = gql`
	mutation ImpersonateUser($id: ID) {
		ImpersonateUser(id: $id) {
			... on User {
				...UserInfo
			}
			... on DatabaseFault {
				message
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${USERINFO_FRAGMENT}
`;

const AuthContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }) {
	const auth = useAuthFunctions();
	return auth.isLoggedIn === auth.loggedInStatus.checking ? (
		<Loading text='Cargando...' />
	) : (
		<AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
	);
}

export const useAuth = () => useContext(AuthContext);

const useAuthFunctions = () => {
	const { config } = useConf();
	const [userData, _setUserData] = useState(null);
	const [notificationCounter, setNotificationCounter] = useState({
		merchantsCount: 0,
		ordersCount: 0,
		productsCount: 0,
	});
	const [locale, setLocale] = useState(config.default_locale);

	const useChangePassword = props => useMutation(CHANGEPASSWORD_MUTATION, props);
	const useImpersonateUser = props => useMutation(IMPERSONATEUSER_MUTATION, props);
	const useLogin = props =>
		useMutation(SIGNIN_MUTATION, {
			...props,
			// since notifications change constantly, refetch after every login
			refetchQueries: ['NotificationCount'],
		});
	const useUpdateUser = props => useMutation(PROFILEUPDATE_MUTATION, props);

	const useRestorePassword = props => useMutation(RESTORE_PASSWORD_MUTATION, props);

	const [logOut] = useMutation(SIGNOUT_MUTATION, {
		onCompleted: () => {
			setIsLoggedIn(loggedInStatus.no);
			_setUserData(null);
			clearCache();
		},
	});

	const { data } = useQuery(ME_QUERY, {
		variables: { locale },
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	});

	// Posible states when checking if we are logged in (JavaScript "Enum")
	const loggedInStatus = {
		no: 1,
		yes: 2,
		checking: 3,
	};

	const [isLoggedIn, setIsLoggedIn] = useState(loggedInStatus.checking);

	const { data: notificationCount } = useQuery(NOTIFICATION_COUNT_QUERY, {
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	});

	const [getLazyNotificationCount, { data: lazyNotificationCount }] = useLazyQuery(NOTIFICATION_COUNT_QUERY, {
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	});

	const setUserData = user => {
		if (user) {
			if (user.__typename === 'User') {
				if (['admin', 'merchant', 'translator'].includes(user.type)) {
					// Always set the user first and then set loggedInStatus
					_setUserData({ ...user });
					setIsLoggedIn(loggedInStatus.yes);
				} else {
					// Not a correct user type: Logout (will unset the userData)
					logOut();
				}
			} else {
				// Error: Not logged in (unset any previous user)
				_setUserData(null);
				setIsLoggedIn(loggedInStatus.no);
				clearCache();
			}
		} else {
			// me returned is null meaning not logged In
			setIsLoggedIn(loggedInStatus.no);
			clearCache();
		}
	};

	useEffect(() => {
		if (data) {
			setUserData(data.me);
		} else if (!userData) {
			setIsLoggedIn(loggedInStatus.checking);
		}
	}, [data, logOut, _setUserData, setIsLoggedIn, loggedInStatus.no, loggedInStatus.yes, loggedInStatus.checking]);

	useEffect(() => {
		if (notificationCount && notificationCount.NotificationCount) {
			setNotificationCounter(notificationCount.NotificationCount);
		}
	}, [notificationCount]);

	useEffect(() => {
		if (lazyNotificationCount && lazyNotificationCount.NotificationCount) {
			setNotificationCounter(lazyNotificationCount.NotificationCount);
		}
	}, [lazyNotificationCount]);

	return {
		loggedInStatus,
		isLoggedIn,
		locale,
		setLocale,
		logOut,
		setUserData,
		useChangePassword,
		useLogin,
		user: userData,
		useImpersonateUser,
		useUpdateUser,
		notificationCounter,
		getLazyNotificationCount,
		useRestorePassword,
	};
};

export default useAuth;
